import { Grid } from "./s25.virtual.grid.component";
import { S25Util } from "../../util/s25-util";

export namespace GridUtil {
    export function setHeaderMetadata<
        HeaderData extends Grid.CustomData,
        RowData extends Grid.CustomData,
        ItemData extends Grid.CustomData,
    >(data: Grid._Data<HeaderData, RowData, ItemData>) {
        for (const header of data.headers) setHeaderMetaData(header);
        for (const header of data.rows) setHeaderMetaData(header);

        data._columnDepth = S25Util.array.depth(
            data.headers,
            (h) => h.subHeaders,
            (h) => !h.hidden && !h._gridData.filtered,
        );
        data._rowDepth = S25Util.array.depth(
            data.rows,
            (r) => r.subHeaders,
            (r) => !r.hidden && !r._gridData.filtered,
        );

        data._columnData = GridUtil.getHeaderData(data.headers);
        data._rowData = GridUtil.getHeaderData(data.rows);
        data._columnCount = data._columnData.length;
        data._rowCount = data._rowData.length;

        const visibleColumns = S25Util.array.count(data._columnData, (header) => +!header.hidden);
        const visibleRows = S25Util.array.count(data._rowData, (header) => +!header.hidden);
        data._visibleColumnCount = visibleColumns;
        data._visibleRowCount = visibleRows;
    }

    export function setHeaderMetaData<HeaderData extends Grid.CustomData>(
        header: Grid._Header<HeaderData>,
        parent?: Grid._Header<HeaderData>,
    ) {
        if (!header._gridData) header._gridData = {};
        header._gridData.parent = parent;

        for (const subHeader of header.subHeaders ?? []) {
            setHeaderMetaData(subHeader, header);
        }
    }

    export function updateItemPosition<
        HeaderData extends Grid.CustomData,
        RowData extends Grid.CustomData,
        ItemData extends Grid.CustomData,
    >(data: Grid._Data<HeaderData, RowData, ItemData>, item: Grid._Item<ItemData>) {
        // Items are provided with a position in terms of width and height of the expanded grid where no columns or rows
        // are hidden. As such we need to calculate the position of each item after columns and rows have been hidden
        item._gridData.left = getPositionAfterHidingHeaders(item.left, data._columnData, data._visibleColumnCount);
        let right = truncateItemByHeader(item.left, item.left + item.width, data._columnData);
        right = getPositionAfterHidingHeaders(right, data._columnData, data._visibleColumnCount);
        item._gridData.width = right - item._gridData.left;

        item._gridData.top = getPositionAfterHidingHeaders(item.top, data._rowData, data._visibleRowCount);
        let bottom = truncateItemByHeader(item.top, item.top + item.height, data._rowData);
        bottom = getPositionAfterHidingHeaders(bottom, data._rowData, data._visibleRowCount);
        item._gridData.height = bottom - item._gridData.top;

        item._gridData.truncated = isItemTruncated(item, data._columnData, data._rowData);
    }

    export function getPositionAfterHidingHeaders<HeaderData extends Grid.CustomData>(
        percent: number,
        headerData: Grid.HeaderMeta<HeaderData>[],
        visibleHeaders: number,
    ) {
        const headers = headerData.length;
        const pos = (percent / 100) * headers; // Convert to header units
        let newPos = pos;
        newPos -= headerData[Math.floor(pos) - 1]?.hiddenSum || 0; // Subtract preceding hidden headers
        if (headerData[Math.floor(pos)]?.hidden) newPos = Math.floor(newPos); // Pos itself is hidden, align to header
        newPos = Math.round(newPos * headers * 100) / (headers * 100); // Align to a 100th of a header
        return (newPos / visibleHeaders) * 100; // Convert back to percent
    }

    export function getPositionBeforeHidingHeaders<HeaderData extends Grid.CustomData>(
        percent: number,
        headerData: Grid.HeaderMeta<HeaderData>[],
        visibleHeaders: number,
    ) {
        const pos = (percent / 100) * visibleHeaders; // Convert to header units
        const visibleHeaderIndex = Math.min(Math.floor(pos), headerData.length - 1);
        const headerIndex = headerData.findIndex((header, i) => i - header.hiddenSum === visibleHeaderIndex);
        const newPos = headerIndex + (percent === 100 ? 1 : pos % 1); // Translate to correct header
        return (newPos / headerData.length) * 100; // Convert back to percent
    }

    export function truncateItemByHeader<HeaderData extends Grid.CustomData>(
        start: number,
        end: number,
        headerData: Grid.HeaderMeta<HeaderData>[],
    ) {
        // If a header is marked with "truncateOverflow" we need to truncate any item which overflows the header
        // If two successive headers have different truncateRef, truncate and return
        let startCol = Math.floor((S25Util.clamp(start, 0, 100) / 100) * headerData.length);
        startCol = S25Util.clamp(startCol, 0, headerData.length - 1);
        const endCol = (S25Util.clamp(end, 0, 100) / 100) * headerData.length;
        let truncateRef = headerData[startCol].truncateRef;
        for (let i = startCol; i < endCol; i++) {
            if (headerData[i].truncateRef !== truncateRef) {
                // Truncate to start of header
                return (i / headerData.length) * 100;
            }
            truncateRef = headerData[i].truncateRef;
        }

        return end;
    }

    export function isItemTruncated<ItemData extends Grid.CustomData, HeaderData extends Grid.CustomData>(
        item: Grid._Item<ItemData>,
        columnData: Grid.HeaderMeta<HeaderData>[],
        rowData: Grid.HeaderMeta<HeaderData>[],
    ) {
        const [firstCol, lastCol] = getGridUnits(item.left, item.width, columnData.length);
        let truncateRef = columnData[firstCol].truncateRef;
        for (let i = firstCol; i <= lastCol; i++) {
            if (columnData[i]?.truncateRef !== truncateRef) return true; // Truncated by "truncateOverflow" on header
            if (columnData[i]?.hidden) return true; // Truncated by hidden header
        }

        const [firstRow, lastRow] = getGridUnits(item.top, item.height, rowData.length);
        truncateRef = rowData[firstRow].truncateRef;
        for (let i = firstRow; i <= lastRow; i++) {
            if (rowData[i]?.truncateRef !== truncateRef) return true; // Truncated by "truncateOverflow" on header
            if (rowData[i]?.hidden) return true; // Truncated by hidden header
        }

        return false;
    }

    export function getGridUnits(offset: number, size: number, count: number) {
        // e is to avoid counting an item as being in a row/column if it's right on the edge
        const e = 1.0e-12;
        const first = Math.floor((S25Util.clamp(offset + e, 0, 100) / 100) * count);
        const last = Math.floor((S25Util.clamp(offset + size - e, 0, 100) / 100) * count);
        return [first, last];
    }

    export function getHeaderData(headers: Grid.Header<Grid.CustomData>[]) {
        const data = getHeaderDataDFS(headers);

        // Find last visible header
        for (let i = data.length - 1; i >= 0; i--) {
            if (!data[i].hidden) {
                data[i].header._gridData.last = true;
                break;
            }
        }

        return data;
    }

    function getHeaderDataDFS<HeaderData extends Grid.CustomData>(
        headers: Grid._Header<HeaderData>[],
        hiddenParent = false,
        truncateRef: Grid.Header<Grid.CustomData> = null,
        data: Grid.HeaderMeta<HeaderData>[] = [],
    ): Grid.HeaderMeta<HeaderData>[] {
        for (let header of headers) {
            const hidden = hiddenParent || header.hidden || header._gridData.filtered;
            if (header.subHeaders) {
                getHeaderDataDFS(header.subHeaders, hidden, header.truncateOverflow ? header : truncateRef, data);
            } else {
                const hiddenSum = (data.at(-1)?.hiddenSum || 0) + +!!hidden;
                data.push({ hidden, hiddenSum, truncateRef: header.truncateOverflow ? header : truncateRef, header });
            }
        }
        return data;
    }

    export function getMaxDragOffset(elem: HTMLElement) {
        const itemElement = elem.closest(".grid--item") as HTMLElement;
        const gridElement = itemElement.closest(".grid--area") as HTMLElement;

        return {
            top: -itemElement.offsetTop,
            bottom: gridElement.offsetHeight - itemElement.offsetTop - itemElement.offsetHeight,
            left: -itemElement.offsetLeft,
            right: gridElement.offsetWidth - itemElement.offsetLeft - itemElement.offsetWidth,
        };
    }

    export function doItemsOverlap(A: Grid.Item<Grid.CustomData>[], B: Grid.Item<Grid.CustomData>[]) {
        // Use left and width percentages to determine whether there is overlap
        const e = 1.0e-12;
        for (let a of A) {
            for (let b of B) {
                const horizontal = a.left + e < b.left + b.width && a.left + a.width - e > b.left;
                if (!horizontal) continue;
                const vertical = a.top + e < b.top + b.height && a.top + a.height - e > b.top;
                if (vertical) return true;
            }
        }
        return false;
    }

    export function positionToHeaders<HeaderData extends Grid.CustomData>(
        offset: number,
        size: number,
        snap: number,
        headerData: Grid.HeaderMeta<HeaderData>[],
    ) {
        const err = snap / 100;
        const start = Math.round(((offset / 100) * headerData.length) / err) * err;
        const end = Math.round((((offset + size) / 100) * headerData.length) / err) * err;
        const headers: Grid.Header<HeaderData>[] = [];
        for (let i = Math.floor(start); i < end; i++) {
            headers.push(headerData[i].header);
        }

        return headers;
    }

    export function getCreatePosition<
        ColumnData extends Grid.CustomData,
        RowData extends Grid.CustomData,
        ItemData extends Grid.CustomData,
    >(
        creating: Grid.Creating,
        grid: Grid._Data<ColumnData, RowData, ItemData>,
        gridArea: HTMLElement,
    ): Grid.CreateData {
        if (!creating) return;

        const { initX, initY, floatX, floatY } = creating;
        const { _rowData, _visibleRowCount, _columnData, _visibleColumnCount } = grid;
        const rowHeight = gridArea.offsetHeight / _visibleRowCount;
        const columnWidth = gridArea.offsetWidth / _visibleColumnCount;

        // Account for flip when dragging forwards vs backwards
        const x = floatX < initX ? initX + columnWidth : initX;
        const y = floatY < initY ? initY + rowHeight : initY;

        // Position in DOM
        const visualTop = (Math.min(y, floatY) / gridArea.offsetHeight) * 100;
        const visualBottom = (Math.max(y, floatY) / gridArea.offsetHeight) * 100;
        const visualLeft = (Math.min(x, floatX) / gridArea.offsetWidth) * 100;
        const visualRight = (Math.max(x, floatX) / gridArea.offsetWidth) * 100;

        // Position after accounting for hidden rows/columns
        const top = GridUtil.getPositionBeforeHidingHeaders(visualTop, _rowData, _visibleRowCount);
        const bottom = GridUtil.getPositionBeforeHidingHeaders(visualBottom, _rowData, _visibleRowCount);
        const left = GridUtil.getPositionBeforeHidingHeaders(visualLeft, _columnData, _visibleColumnCount);
        const right = GridUtil.getPositionBeforeHidingHeaders(visualRight, _columnData, _visibleColumnCount);

        return { top, height: bottom - top, left, width: right - left };
    }

    /**
     * Initializes an item for internal use
     * @param item Item to initialize
     * @ChangeDetection false
     */
    export function initializeItem<
        ColumnData extends Grid.CustomData,
        RowData extends Grid.CustomData,
        ItemData extends Grid.CustomData,
    >(data: Grid._Data<ColumnData, RowData, ItemData>, item: Grid._Item<ItemData>): Grid._Item<ItemData> {
        data._itemById.set(item.id, item);
        item._gridData ??= {} as Grid._Item<ItemData>["_gridData"]; // Initialize _gridData
        item.draggable ??= false;
        item.data ??= {} as ItemData;
        item.linkedItems ??= new Set([item.id]);
        GridUtil.updateItemPosition(data, item);

        item.candidate ??= new Proxy({} as any, {
            get(target, prop, receiver) {
                // @ts-ignore
                return Reflect.get(...arguments) ?? item.data[prop];
            },
        });

        return item;
    }

    // Creates a map from column X and row Y to a list of items that are present in that slice of the grid
    export function createItemIndex<ItemData extends Grid.CustomData>(
        items: Grid.Item<ItemData>[],
        rowCount: number,
        colCount: number,
    ): Grid.ItemIndex<ItemData> {
        const byRow = new Map<number, Map<number, Grid.Item<ItemData>[]>>();
        for (const item of items) {
            const [firstRow, lastRow] = getGridUnits(item.top, item.height, rowCount);
            for (let r = firstRow; r <= lastRow; r++) {
                const byCol = byRow.get(r) || new Map<number, Grid.Item<ItemData>[]>();
                byRow.set(r, byCol);

                const [firstCol, lastCol] = getGridUnits(item.left, item.width, colCount);
                for (let c = firstCol; c <= lastCol; c++) {
                    const colArr = byCol.get(c) || [];
                    byCol.set(c, colArr);

                    colArr.push(item);
                }
            }
        }

        return byRow;
    }

    export function updateOverlap<ItemData extends Grid.CustomData>(
        itemIndex: Grid.ItemIndex<ItemData>,
        item: Grid._Item<ItemData>,
        rowCount: number,
        colCount: number,
    ) {
        if (item.isOverlapItem || item.noInteraction || item.hidden || item._gridData.filtered) return;
        item.overlapping = [];
        const seen = new Set<number | string>();

        const [firstRow, lastRow] = getGridUnits(item.top, item.height, rowCount);
        const [firstCol, lastCol] = getGridUnits(item.left, item.width, colCount);
        for (let r = firstRow; r <= lastRow; r++) {
            for (let c = firstCol; c <= lastCol; c++) {
                for (const candidate of itemIndex.get(r).get(c)) {
                    if (seen.has(candidate.id) || candidate.noInteraction || candidate.id === item.id) continue;
                    if (candidate.hidden || candidate._gridData.filtered) continue;
                    if (!GridUtil.doItemsOverlap([item], [candidate])) continue;
                    item.overlapping.push(candidate);
                    seen.add(candidate.id);
                }
            }
        }
    }

    export function createOverlapItems<
        ColumnData extends Grid.CustomData,
        RowData extends Grid.CustomData,
        ItemData extends Grid.CustomData,
    >(data: Grid._Data<ColumnData, RowData, ItemData>, items: Grid.Item<ItemData>[]): Grid.Item<ItemData>[] {
        const overlapItems: Grid.Item<ItemData>[] = [];
        const seen = new Set<string | number>();
        for (const item of items) {
            if (seen.has(item.id) || !item.overlapping?.length) continue;
            seen.add(item.id);

            let left = item.left;
            let top = item.top;
            let right = item.left + item.width;
            let bottom = item.top + item.height;
            let overlappingItems: Grid.Item<ItemData>[] = [item];

            const queue: Grid.Item<ItemData>[] = [item];
            while (queue.length) {
                const overlapping = queue.pop();

                for (const next of overlapping.overlapping ?? []) {
                    if (seen.has(next.id)) continue;
                    seen.add(next.id);
                    queue.push(next);

                    overlappingItems.push(next);
                    left = Math.min(left, next.left);
                    top = Math.min(top, next.top);
                    right = Math.max(right, next.left + next.width);
                    bottom = Math.max(bottom, next.top + next.height);
                }
            }

            const overlapItem: Grid.Item<ItemData> = initializeItem(data, {
                id: `overlap-item-${overlapItems.length}`,
                noInteraction: true,
                overlapping: overlappingItems,
                left,
                top,
                width: right - left,
                height: bottom - top,
                ariaLabel: "",
                isOverlapItem: true,
            });
            overlapItems.push(overlapItem);
        }

        return overlapItems;
    }

    export function updateOverlaps<
        ColumnData extends Grid.CustomData,
        RowData extends Grid.CustomData,
        ItemData extends Grid.CustomData,
    >(grid: Grid._Data<ColumnData, RowData, ItemData>, createOverlapItems: boolean): void {
        // Remove existing overlap items
        if (createOverlapItems) {
            S25Util.array.inplaceFilter(grid._visibleItems, (item) => !item.isOverlapItem);
        }

        // Update overlapping data
        grid._itemIndex = GridUtil.createItemIndex(grid._visibleItems, grid._rowCount, grid._columnCount);
        for (const item of grid._visibleItems) {
            GridUtil.updateOverlap(grid._itemIndex, item, grid._rowCount, grid._columnCount);
        }

        // Create new overlap items
        if (createOverlapItems) {
            const overlapItems = GridUtil.createOverlapItems(grid, grid._visibleItems);
            Array.prototype.push.apply(grid._visibleItems, overlapItems);
        }
    }

    // Sort by left and top such that tabbing goes row by row
    export function sortItems<ItemData extends Grid.CustomData>(items: Grid.Item<ItemData>[]) {
        items.sort((a, b) => {
            if (a.top !== b.top) return a.top - b.top;
            return a.left - b.left;
        });
    }
}
