import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    TemplateRef,
    ViewChild,
    ViewEncapsulation,
} from "@angular/core";
import { Grid, S25VirtualGridComponent } from "./s25.virtual.grid.component";
import { Bind } from "../../decorators/bind.decorator";
import { Merge, OmitPrivate } from "../../pojo/Util";
import { Proto } from "../../pojo/Proto";
import _Item = Grid._Item;
import { S25Util } from "../../util/s25-util";
import { UserprefService } from "../../services/userpref.service";
import { MultiselectModelI } from "../s25-multiselect/s25.multiselect.component";
import { DowGridUtil } from "./s25.dow.grid.util";
import { DropDownItem } from "../../pojo/DropDownItem";
import { StandardScheduleService } from "../../services/standard.schedule.service";
import { Item } from "../../pojo/Item";

@Component({
    selector: "s25-ng-dow-pattern-grid",
    template: `
        <s25-ng-virtual-grid
            [dataSource]="virtualGridDataSource"
            [cornerTemplate]="corner"
            [rowHeaderTemplate]="rowHeaderTemplate"
            [itemTemplate]="itemTemplate"
            [optionsLeftTemplate]="optionsLeft"
            [optionsMiddleTemplate]="optionsMiddleTemplate"
            [optionsRightTemplate]="optionsRightTemplate"
            [optionsBelowTemplate]="optionsBelowTemplate"
            [canDragY]="canDragY"
            [canDragX]="canDragX"
            [hasMinimap]="hasMinimap"
            [hasRefresh]="hasRefresh"
            [hasUndo]="hasUndo"
            [canMoveTruncatedItems]="canMoveTruncatedItems"
            [allowOverlap]="allowOverlap"
            [snapToXStep]="snapToXStep"
            [snapToYStep]="snapToYStep"
            [pollForChanges]="pollForChanges"
            [pollInterval]="pollInterval"
            [findOverlap]="findOverlap"
            [createOverlapItems]="createOverlapItems"
            [class.hideColHeaderHourTicks]="spanHours > 1"
        ></s25-ng-virtual-grid>

        <ng-template #corner>
            <s25-ng-office-hours-slider
                [prefName]="null"
                [(start)]="startHour"
                [(end)]="endHour"
                (onChange)="onHoursChange()"
            ></s25-ng-office-hours-slider>
        </ng-template>

        <ng-template #optionsLeft>
            <ng-container
                [ngTemplateOutlet]="optionsLeftTemplate"
                [ngTemplateOutletContext]="{ defaultOptions: leftDefaults }"
            ></ng-container>
            @if (!optionsLeftTemplate) {
                <ng-container [ngTemplateOutlet]="leftDefaults"></ng-container>
            }
        </ng-template>

        <ng-template #leftDefaults let-defaultOptions="defaultOptions">
            @if (dowMultiselectModel) {
                <s25-ng-multiselect-popup [modelBean]="dowMultiselectModel"></s25-ng-multiselect-popup>
            }
            @if (hasStandardSchedule && hasStandardSchedules) {
                <s25-ng-dropdown-search-criteria
                    [type]="'standardSchedules'"
                    [(chosen)]="standardSchedule"
                    (chosenChange)="changeStandardSchedule($event)"
                    [placeholder]="'Standard Schedules'"
                    [emptyText]="'No Standard Schedules'"
                />
            }
            <ng-container [ngTemplateOutlet]="defaultOptions"></ng-container>
        </ng-template>
    `,
    styles: `
        :host {
            --grid-column-width: var(--column-width, 150px);
        }

        ::ng-deep s25-ng-dow-pattern-grid .hideColHeaderHourTicks .grid--column-header.leaf {
            background: none;
        }

        ::ng-deep s25-ng-dow-pattern-grid .hideColHeaderHourTicks .grid--column-header.leaf::before {
            border-color: transparent;
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.Emulated,
})
export class S25DowGridComponent<
        HeaderData extends Grid.CustomData,
        RowData extends Grid.CustomData,
        ItemData extends DowGrid.CustomItemData,
    >
    implements OnChanges, OnInit
{
    // Required
    @Input({ required: true }) dataSource: DowGrid.DataSource<HeaderData, RowData, ItemData>;
    @Input({ required: true }) startHour: Proto.Integer;
    @Input({ required: true }) endHour: Proto.Integer;
    // Optional
    @Input() dows: string[]; // Defaults to dows present in data
    @Input() visibleDows: string[]; // Defaults to two dows from dows or data
    @Input() displayShadows: boolean = false; // Display an item in multiple places if DOW patterns overlap
    @Input() rowHeaderTemplate: TemplateRef<any>;
    @Input() itemTemplate: TemplateRef<any>;
    @Input() optionsLeftTemplate: TemplateRef<any>; // Template for left part of options bar
    @Input() optionsMiddleTemplate: TemplateRef<any>; // Template for middle part of options bar
    @Input() optionsRightTemplate: TemplateRef<any>; // Template for right part of options bar
    @Input() optionsBelowTemplate: TemplateRef<any>; // Template for below the options bar
    @Input() canDragX: boolean = false;
    @Input() canDragY: boolean = false;
    @Input() hasMinimap: boolean = false;
    @Input() canMoveTruncatedItems: boolean = false;
    @Input() allowOverlap: boolean = false;
    @Input() hasRefresh: boolean = false;
    @Input() hasUndo: boolean = false;
    @Input() snapToXStep: number = 5 / 60; // Fraction of a column width
    @Input() snapToYStep: number = 1; // Fraction of a row height
    @Input() pollForChanges: boolean = false;
    @Input() pollInterval: Proto.Milliseconds = 1_000;
    @Input() spanHours: number = 1; // If not 1, then only every spanHours will have a column header
    @Input() hasStandardSchedule: boolean = false;
    @Input() findOverlap: boolean = false;
    @Input() createOverlapItems: boolean = false;

    // Template views
    @ViewChild(S25VirtualGridComponent) virtualGrid: S25VirtualGridComponent<HeaderData, RowData, ItemData>;

    virtualGridDataSource: Grid.DataSource<HeaderData, RowData, ItemData>;
    data: Awaited<ReturnType<typeof this.getGridData>>;
    dowColumnIndex: Map<string, number>;
    _itemById: Map<_Item<ItemData>["id"], _Item<ItemData>>;
    dowMultiselectModel: MultiselectModelI;
    is24Hours = false;
    dowIndex: Map<string, number> = new Map();
    standardSchedule: DropDownItem;
    hasStandardSchedules: boolean = false;

    constructor(private changeDetector: ChangeDetectorRef) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.dows || changes.visisbleDows || changes.startHour || changes.endHour) {
            this.onHoursChange();
        } else if (changes.dataSource || changes.displayShadows) {
            this.refresh();
        }
        if (changes.dows) {
            this.createDowIndex();
        }
    }

    ngOnInit() {
        this.virtualGridDataSource = {
            getData: this.getGridData,
            onItemsPickedUp: this.onItemsPickedUp,
            onItemsDragged: this.onItemsDragged,
            onItemsPutDown: this.onItemsPutDown,
            afterItemsPutDown: this.afterItemsPutDown,
            onUndo: this.onUndo,
            onRedo: this.onRedo,
            poll: this.poll,
        };
    }

    @Bind
    async onItemsPickedUp(items: Grid.Item<DowGrid._CustomItemData<ItemData>>[]) {
        items = this.filterShadows(items);
        this.updateCandidateData(items);
        return (await this.dataSource.onItemsPickedUp?.(items)) || true;
    }

    @Bind
    onItemsDragged(items: Grid.Item<DowGrid._CustomItemData<ItemData>>[]) {
        items = this.filterShadows(items);
        this.updateCandidateData(items);
        return this.dataSource.onItemsDragged?.(items);
    }

    @Bind
    async onItemsPutDown(items: Grid.Item<DowGrid._CustomItemData<ItemData>>[]) {
        items = this.filterShadows(items);
        this.updateCandidateData(items);
        return this.dataSource.onItemsPutDown ? await this.dataSource.onItemsPutDown?.(items) : true;
    }

    @Bind
    async afterItemsPutDown(items: Grid.Item<DowGrid._CustomItemData<ItemData>>[]) {
        this.dataSource.afterItemsPutDown?.(items);
    }

    @Bind
    async onUndo(items: Grid.Item<DowGrid._CustomItemData<ItemData>>[]) {
        items = this.filterShadows(items);
        this.updateCandidateData(items);
        for (const item of items) Object.assign(item.data, item.candidate);
        this.dataSource.onUndo?.(items);
    }

    @Bind
    async onRedo(items: Grid.Item<DowGrid._CustomItemData<ItemData>>[]) {
        items = this.filterShadows(items);
        this.updateCandidateData(items);
        for (const item of items) Object.assign(item.data, item.candidate);
        this.dataSource.onRedo?.(items);
    }

    @Bind
    async poll(): Promise<Grid.PollData<ItemData>> {
        if (!this.dataSource.poll) return {};
        const pollData = await this.dataSource.poll();
        return this.processPollData(pollData);
    }

    @Bind
    async getGridData(
        query: Grid.DataQuery,
    ): Promise<Grid._Data<HeaderData, RowData, DowGrid._CustomItemData<ItemData>>> {
        if (!this.dataSource) return { headers: [], rows: [], items: [], _itemById: new Map() };

        const [rawData, is24Hours, standardSchedules] = await Promise.all([
            this.dataSource.getData(query),
            UserprefService.getIs24HourTime(),
            this.hasStandardSchedule && StandardScheduleService.getStandardScheduleList(Item.Ids.Location),
        ]);
        if (!rawData) return;
        this.is24Hours = is24Hours;
        this.hasStandardSchedules = !!standardSchedules?.length;
        this.data = this.processData(rawData as DowGrid.Data<HeaderData, RowData, ItemData>);

        this.dowMultiselectModel = {
            title: "Meeting Patterns",
            buttonText: "Meeting Patterns",
            hasSelectAll: true,
            hasSelectNone: true,
            usePopover: true,
            items: this.dows.map((dow) => ({ itemId: dow, itemName: dow })),
            selectedItems: this.visibleDows.map((dow) => ({ itemId: dow, itemName: dow })),
            action: () => {
                if (!this.dowMultiselectModel.selectedItems.length) {
                    return alert("Please select at least one meeting pattern");
                }
                this.visibleDows = this.dowMultiselectModel.selectedItems.map((item) => item.itemName);
                this.updateHeaders();
            },
        };

        return this.data;
    }

    refresh() {
        if (!this.dataSource || !this.virtualGridDataSource || !this.virtualGrid) return;
        return this.virtualGrid.refresh();
    }

    processData(data: DowGrid.Data<HeaderData, RowData, ItemData>) {
        const dataDows = data.items.map((item) => item.data.dow);
        dataDows.push(...(this.visibleDows || []), ...(this.dows || []));
        const allDows = S25Util.array.unique([...(this.visibleDows || []), ...(this.dows || []), ...dataDows]);

        this.dows = allDows;
        if (!this.visibleDows?.length) this.visibleDows = allDows.slice(0, 2);
        this.setDowColumnIndex();

        const items = this.processItems(data);
        const rows = data.rows;
        const headers = this.getHeaders();
        return { ...data, headers, rows, items, _itemById: this._itemById };
    }

    setDowColumnIndex() {
        this.dowColumnIndex = new Map((this.dows || []).map((dow, i) => [dow, i * 24])); // Map DOW to its first column index
    }

    getItemPosition(itemData: { startHour: number; endHour: number; dow: string }) {
        return DowGridUtil.getItemPosition(itemData, this.dows.length * 24, this.dowColumnIndex.get(itemData.dow));
    }

    processItems(data: DowGrid.Data<HeaderData, RowData, ItemData>) {
        this._itemById = new Map();
        let items: Grid.Item<DowGrid._CustomItemData<ItemData>>[] = [];
        for (let item of data.items) {
            const gridItem = this.dowItemToGridItem(item);
            if (!gridItem) continue; // Ignore invalid positioned items

            this._itemById.set(gridItem.id, gridItem);
            items.push(gridItem);

            // Create shadows
            if (this.displayShadows) {
                for (let shadow of this.generateShadows(gridItem)) {
                    items.push(shadow);
                    this._itemById.set(shadow.id, shadow);
                }
            }
        }

        // Sort by dow and time
        items.sort(this.sortByDowAndTime);

        this.dataSource.postProcessItems?.(items);

        return items;
    }

    @Bind
    sortByDowAndTime(a: Grid.Item<ItemData>, b: Grid.Item<ItemData>) {
        // Sort by dow first
        if (a.data.dow !== b.data.dow) {
            return this.dowIndex.get(a.data.dow) - this.dowIndex.get(b.data.dow);
        }

        // Prefer earlier start hour
        if (a.data.startHour !== b.data.startHour) return a.data.startHour - b.data.startHour;

        // Prefer later end hour
        return b.data.endHour - a.data.endHour;
    }

    @Bind
    addShadows() {
        const shadows: Grid.Item<DowGrid._CustomItemData<ItemData>>[] = [];
        for (const item of this.data.items) {
            for (let shadow of DowGridUtil.generateShadows(item, this.dows, this.dowColumnIndex, this.is24Hours)) {
                shadows.push(shadow);
                this._itemById.set(shadow.id, shadow);
            }
        }
        Array.prototype.push.apply(this.data.items, shadows);
    }

    @Bind
    removeShadows() {
        // Overrides display shadows setting
        S25Util.array.inplaceFilter(this.data.items, (item) => !item.data._isShadow);
    }

    generateShadows(item: Grid.Item<ItemData>) {
        if (!this.displayShadows || !item || item.data.noShadows) return [];
        return DowGridUtil.generateShadows(item, this.dows, this.dowColumnIndex, this.is24Hours);
    }

    /**
     * Generate headers from dow and visible hours
     */
    getHeaders(): Grid.Header<HeaderData>[] {
        return DowGridUtil.getHeaders(
            this.visibleDows,
            this.dows,
            this.startHour,
            this.endHour,
            this.is24Hours,
            this.spanHours,
        );
    }

    filterShadows(items: Grid.Item<DowGrid._CustomItemData<ItemData>>[]) {
        return items.filter((item) => !item.data._isShadow);
    }

    getTimesFromPosition(item: Grid.Item<ItemData>) {
        return DowGridUtil.getTimesFromPosition(item, this.dows);
    }

    @Bind
    processPollData(pollItem: DowGrid.PollData<ItemData>): Grid.PollData<ItemData> {
        const newPollItem: Grid.PollData<ItemData> = { ...pollItem, items: {} };
        for (let [itemId, itemData] of Object.entries(pollItem?.items || {})) {
            const newItemData: Grid.PollItemData<ItemData> = { updateItem: itemData.updateItem };
            newPollItem.items[itemId] = newItemData;

            // Short circuit if deleting
            if (itemData.delete) {
                newItemData.delete = true;
                continue;
            }

            let item: Grid._Item<DowGrid._CustomItemData<ItemData>>;
            if (itemData.create) {
                let { left, width } = this.getItemPosition(itemData.create.data);
                item = {
                    ...itemData.create,
                    left,
                    width,
                    ariaLabel: DowGridUtil.getAriaLabel(itemData.create, this.is24Hours),
                };

                // Set these regardless of whether the item is added to the virtual grid
                this._itemById.set(item.id, item);
            } else {
                item = this._itemById.get(itemId); // If not set by create, get existing item
            }

            if (itemData.moveTo) {
                // Set dow/times in item data
                item.data.dow = itemData.moveTo.dow ?? item.data.dow;
                item.data.startHour = itemData.moveTo.startHour ?? item.data.startHour;
                item.data.endHour = itemData.moveTo.endHour ?? item.data.endHour;
            }

            if (itemData.create) {
                newItemData.create = item;
            } else {
                // Move item
                const { dow, startHour, endHour } = item.data;
                let { left, width } = this.getItemPosition({ dow, startHour, endHour });
                newItemData.moveTo = { top: itemData.moveTo.top, left, width };
            }
        }

        return newPollItem;
    }

    staticRefresh(data: typeof this.data) {
        this.virtualGrid.staticRefresh(data);
    }

    getItems() {
        return this.data.items;
    }

    onHoursChange() {
        this.updateHeaders();
    }

    updateHeaders() {
        if (!this.virtualGrid?.setColumnHeaders) return;
        this.virtualGrid.setColumnHeaders(this.getHeaders());
    }

    dowItemToGridItem(item: DowGrid.Item<ItemData>) {
        // Calculate position of item
        const position = this.getItemPosition(item.data);
        if (isNaN(position.left) || isNaN(position.width)) return; // Ignore invalid positioned items
        const gridItem = Object.assign(item, position) as Grid.Item<unknown> as Grid.Item<
            DowGrid._CustomItemData<ItemData>
        >; // Extend original item instead of copying
        gridItem.ariaLabel = DowGridUtil.getAriaLabel(item, this.is24Hours);
        gridItem.data._fitsSchedule = true;
        return gridItem;
    }

    addItems(items: DowGrid.Item<ItemData>[], generateShadows = true) {
        const gridItems: Grid.Item<ItemData>[] = [];
        for (let item of items) {
            const gridItem = this.dowItemToGridItem(item);
            if (!gridItem) continue; // Ignore invalid items

            gridItems.push(gridItem);
            if (this.displayShadows && generateShadows) gridItems.push(...this.generateShadows(gridItem));
        }
        this.virtualGrid.addItems(gridItems);
    }

    removeItems(remove: (item: DowGrid.Item<ItemData>) => boolean): void {
        this.virtualGrid.removeItems(remove);
    }

    forcePoll() {
        return this.virtualGrid.forcePoll();
    }

    createDowIndex() {
        if (!this.dows) return;
        this.dowIndex = new Map(this.dows.map((dow, i) => [dow, i]));
    }

    updateCandidateData(items: Grid.Item<DowGrid._CustomItemData<ItemData>>[]) {
        for (const item of items) {
            const { dow, startHour, endHour } = this.getTimesFromPosition(item);
            Object.assign(item.candidate, { dow, startHour, endHour });
        }
    }

    filterRows(filter: (row: Grid.Row<RowData>) => boolean, update: boolean = true) {
        this.virtualGrid.filterRows(filter, update);
    }

    clearRowFilter(update: boolean = true) {
        this.virtualGrid.clearRowFilter(update);
    }

    filterItems(filter: (Item: DowGrid.Item<ItemData>) => boolean, update: boolean = true) {
        this.virtualGrid.filterItems((item: DowGrid._Item<ItemData>) => {
            if (item.data._isStandardSchedule) return true; // Keep standard schedule items
            return filter(item);
        }, update);
    }

    clearItemFilter(update: boolean = true) {
        this.virtualGrid.clearItemFilter(update);
    }

    allItems(): Grid.Item<DowGrid._CustomItemData<ItemData>>[] {
        return this.virtualGrid.allItems();
    }

    visibleItems(): DowGrid.Item<ItemData>[] {
        return this.virtualGrid.visibleItems();
    }

    allRows(): Grid.Row<RowData>[] {
        return this.virtualGrid.allRows();
    }

    async changeStandardSchedule(item: DropDownItem) {
        // Fetch schedule
        const schedule = await S25Util.Result(StandardScheduleService.getStandardSchedule(Number(item.itemId)));
        if (schedule.error) {
            alert("Something went wrong when fetching the standard schedule");
            return;
        }

        // Remove previous schedule
        this.removeItems((item: DowGrid.Item<DowGrid._CustomItemData<ItemData>>) => item.data._isStandardSchedule);

        // Update schedule status for all items
        const fitsSchedule = DowGridUtil.scheduleChecker(schedule.data);
        for (const item of this.allItems() as DowGrid.Item<DowGrid._CustomItemData<ItemData>>[]) {
            item.data._fitsSchedule = fitsSchedule(item);
        }

        // Add new schedule
        this.addItems(DowGridUtil.getStandardScheduleItems<ItemData>(schedule.data), false);
    }

    bringToFront(item: DowGrid.Item<ItemData>): void {
        this.virtualGrid.bringToFront(item as Grid.Item<ItemData>);
    }
}

export namespace DowGrid {
    export type DataSource<
        HeaderData extends Grid.CustomData,
        RowData extends Grid.CustomData,
        ItemData extends CustomItemData,
    > = {
        getData: (query: Grid.DataQuery) => Promise<Data<HeaderData, RowData, OmitPrivate<ItemData>>>;
        onItemsPickedUp?: (items: Grid.Item<ItemData>[]) => Promise<boolean>;
        onItemsDragged?: (items: Grid.Item<ItemData>[]) => void;
        onItemsPutDown?: (items: Grid.Item<ItemData>[]) => Promise<boolean>;
        afterItemsPutDown?: (items: Grid.Item<ItemData>[]) => void;
        poll?: () => Promise<PollData<ItemData>>;
        postProcessItems?: (items: Grid.Item<ItemData>[]) => void;
        onUndo?: Grid.DataSource<HeaderData, RowData, ItemData>["onUndo"];
        onRedo?: Grid.DataSource<HeaderData, RowData, ItemData>["onRedo"];
    };

    export type Data<
        HeaderData extends Grid.CustomData,
        RowData extends Grid.CustomData,
        ItemData extends CustomItemData,
    > = Merge<Omit<Grid.Data<HeaderData, RowData, ItemData>, "headers">, { items: Item<ItemData>[] }>;

    export type _Data<
        HeaderData extends Grid.CustomData,
        RowData extends Grid.CustomData,
        ItemData extends CustomItemData,
    > = Merge<Omit<Grid._Data<HeaderData, RowData, ItemData>, "headers">, { items: _Item<ItemData>[] }>;

    // Omit left and width because they will be calculated using dow and times
    export type Item<ItemData extends CustomItemData> = Omit<_Item<ItemData>, "ariaLabel">;

    export type _Item<ItemData extends CustomItemData> = Omit<Grid.Item<_CustomItemData<ItemData>>, "left" | "width">;

    export type CustomItemData = {
        // Dow and times will be used to calculate left and width for the virtual grid
        dow: string;
        startHour: number; // Hours, e.g. 12.2 for 12:12
        endHour: number; // Hours, e.g. 12.2 for 12:12

        // Other
        noShadows?: boolean; // If true the item will not generate any shadows
    };

    export type _CustomItemData<ItemData extends CustomItemData> = ItemData & {
        // Set by component
        _isShadow?: boolean; // This will be set to "true" for any generated shadow items
        _realItem?: _Item<_CustomItemData<ItemData>>;
        _isStandardSchedule?: boolean; // This will be set to "true" for any generated standard schedule items
        _fitsSchedule?: boolean; // This will be set to "true" for any item that fits the standard schedule
    };

    export type PollData<ItemData extends CustomItemData> = {
        items?: { [itemId: string]: PollItemData<ItemData> };
        postPoll?: () => void;
    };

    export type PollItemData<ItemData extends CustomItemData> = {
        moveTo?: {
            dow: string;
            startHour: number;
            endHour: number;
            top: number; // Percentage of grid height
        };
        delete?: boolean; // Set to true to delete item from grid
        create?: Item<ItemData>; // Creates a new item
        updateItem?: (data: Item<ItemData>) => void; // This callback will be called with the item's custom data
    };
}
